import axios from 'axios';
import { url } from '../utils/url';

export const getProductos = async (token) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    };
    const response = await axios.get(`${url}/api/stripe/product/all`, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const createProducto = async (data, token) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    };
    const response = await axios.post(`${url}/api/stripe/product/create`, data, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}