import { create } from 'zustand';
import { findAllUsers, login, logout, register, updateUser, verify } from '../api/user.api';
import { useTokenStore } from './token.store';

export const useUserStore = create((set, get) => ({
  verified: false,
  loading: false,
  error: null,
  user: null,
  users: [],
  setUser: (user) => set({ user }),
  onLogin: async (loginData) => {
    try {
      const setToken = useTokenStore.getState().setToken;
      set({ loading: true });
      const response = await login(loginData);
      if (response && response.user.email) {
        set({ user: response.user, loading: false });
        setToken(response.token);
      } else {
        throw new Error(response.message);
      }
      
    } catch (error) {
      set({ loading: false, error: error.message });
      setTimeout(() => {
        set({ error: null });
      }, 2000);
    }
  },
  onRegister: async (registerData) => {
    try {
      const setToken = useTokenStore.getState().setToken;
      set({ loading: true });
      const response = await register(registerData);
      set({ user: response.user, loading: false });
      setToken(response.token);
    } catch (error) {
      set({ loading: false });
    }
  },
  onLogout: async (onSuccess) => {
    await logout();
    useTokenStore.getState().setToken(null);
    set({ user: null })
    onSuccess();
  },
  onVerify: async () => {
    try {
      set({ loading: true });
      const token = useTokenStore.getState().token;
      const user = await verify(token);
      if (user && user.email) {
        set({ user, loading: false });
      } else {
        throw new Error(user.message);
      }
      set({ user, loading: false, verified: true });
    } catch (error) {
      set({ loading: false, verified: true });
    }
  },
  onGetUsers: async () => {
    try {
      set({ loading: true });
      const users = await findAllUsers();
      set({ users, loading: false });
    } catch (error) {
      set({ loading: false });
    }
  },
  onUpdateUser: async (user, onSuccess) => {
    try {
      set({ loading: true });
      const updatedUser = await updateUser(user);
      const users = get().users;
      const index = users.findIndex(u => u._id === updatedUser._id);
      users[index] = updatedUser;
      set({ users, loading: false });
      onSuccess();
    } catch (error) {
      set({ loading: false });
    }
  }
}));
