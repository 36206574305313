import axios from 'axios';
import { url } from '../utils/url';

export const getEstudios = async (token) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    };
    const response = await axios.get(`${url}/api/estudio/all`, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const createEstudio = async (data, token) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    };
    const response = await axios.post(`${url}/api/estudio/create`, data, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

// export const getEstudioById = async (id) => {
//   try {
//     const response = await axios.get(`${url}/api/estudio/${id}`, { withCredentials: true });
//     return response.data;
//   } catch (error) {
//     return error.response.data;
//   }
// }

export const updateEstudio = async (id, data, token) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    };
    const response = await axios.put(`${url}/api/estudio/${id}`, data, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const deleteEstudio = async (id, token) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    };
    const response = await axios.delete(`${url}/api/estudio/${id}`, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}