import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const useTokenStore = create(persist((set, get) => ({
  token: null,
  setToken: (token) => set({ token }),
}), {
  name: 'token-storage',
  storage: createJSONStorage(() => localStorage),
}));
